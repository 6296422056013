//@ts-check
import React from 'react'
import { Helmet } from 'react-helmet'
import Faq from '../components/Faq'
import Layout from '../components/Layout'

import { bodyTagManager, drift, driftConfig, tagManager } from '../scripts'
import favicon from '../assets/images/favicon.ico'
import Button from '../components/elements/Button'


class Ayuda extends React.Component {

  render() {
    const siteTitle = "Preguntas Frecuentes de digital signage | ScreenFox";
    const siteDescription = "Las preguntas más frecuentes sobre señalización digital y sobre ScreenFox. Desde lo que es ScreenFox, hasta precios. ";

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet>
            <title>{siteTitle}</title>

            <html lang="es" />
            <link rel="alternate" href={`https://getscreenfox.com${this.props.location.pathname}`} hrefLang="es" />
            <meta name="description" content={siteDescription} />
            <link rel="icon" href={favicon} type="image/x-icon" />

            <meta property="og:title" content={siteTitle} />
            <meta property="og:description" content={siteDescription} />
            <meta property="og:url" content="http://getscreenfox.com/ayuda" />

            <script>{drift}</script>
            <script>{driftConfig}</script>
            <script>{tagManager}</script>
          </Helmet>

          <div
            dangerouslySetInnerHTML={{
              __html: bodyTagManager,
            }}></div>


          <div style={{ background: 'white', padding: '60px 0px' }}>

            {/* change styles */}
            <div className={"container"}>
              <h1 style={{ marginTop: "80px", marginBottom: "80px" }}>
                Preguntas frecuentes.
                </h1>
              <Faq></Faq>

              <div style={{textAlign: "center", padding: "40px"}}>
                <h3>¿No es lo que estabas buscando?</h3>
                <Button tag="a" color="primary" wideMobile href="https://support.getscreenfox.com/">
                  Visita nuestro Centro de Ayuda
                    </Button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Ayuda