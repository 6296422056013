//@ts-check
import React, { Component } from 'react'
import { Accordion } from 'semantic-ui-react'
import style from "./faq.module.css"

export default class Faq extends Component {
  constructor(props) {
    super(props)

    this.state = { activeIndex: 0 }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    return (
      <Accordion>
        <div className={style.title}>
          <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
            ¿Qué es ScreenFox?
          </Accordion.Title>
        </div>


        {activeIndex === 0 &&
          <Accordion.Content active={activeIndex === 0}>
            <p style={{paddingBottom: "40px"}}>
              ScreenFox es un dispositivo que se conecta a cualquier pantalla para desplegar imágenes y videos de la manera más fácil.
              Todo el contenido puede ser controlado desde nuestro sistema de gestión en una página web.
            </p>
          </Accordion.Content>
        }

        <div className={style.title}>
          <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
            ¿Qué necesito para empezar a trabajar con ScreenFox?
          </Accordion.Title>
        </div>


        {activeIndex === 1 &&
          <Accordion.Content active={activeIndex === 1}>
            <p style={{paddingBottom: "40px"}}>
              <strong>1.</strong> &nbsp;&nbsp;&nbsp;Contáctanos <a style={{cursor: "pointer", color: '#3b3461'}} onClick={() => {
                
                  // drift.api.showWelcomeMessage()
                
                
              }}>aquí</a> para crearte una cuenta de usuario
              <br />
              <strong>2.</strong> &nbsp;&nbsp;Por lo menos un reproductor de contenido de ScreenFox (4k Media Player)
              <br />
              <strong>3.</strong> &nbsp;&nbsp;Por lo menos una pantalla. Puede ser también un video wall, proyector de video o cualquier dispositivo de visualización.
            </p>
          </Accordion.Content>
        }

        <div className={style.title}>
          <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
            ¿Cuánto cuesta?
          </Accordion.Title>
        </div>


        {activeIndex === 2 &&
          <Accordion.Content>
            <p>
              El precio de ScreenFox comienza en $460 pesos al mes por licencia.
              <br />
              Los equipos de ScreenFox cuestan $6,000 pesos. Incluye envíos gratis en toda la república.
            </p>
            <br/>
          </Accordion.Content>
        }

        <div className={style.title}>
          <Accordion.Title active={activeIndex === 5} index={5} onClick={this.handleClick}>
            ¿Hay descuentos por volumen?
          </Accordion.Title>
        </div>


        {activeIndex === 5 &&
          <Accordion.Content>
            <p style={{paddingBottom: "40px"}}> Si cuentas con más de una pantalla, tenemos descuentos por volumen. Nos puedes pedir información <a style={{cursor: "pointer", color: '#3b3461'}} onClick={() => {
              // drift.api.showWelcomeMessage()
            }}>aquí</a>.</p>
          </Accordion.Content>
        }

        <div className={style.title}>
          <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}>
            ¿Cuántos dispositivos pueden usarse con una licencia de ScreenFox?
          </Accordion.Title>
        </div>


        {activeIndex === 3 &&
          <Accordion.Content>
            <p style={{paddingBottom: "40px"}}>
              Una licencia te permite utilizar un dispositivo.
            </p>
          </Accordion.Content>
        }

        <div className={style.title}>
          <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleClick}>
            ¿ScreenFox es compatible con todas las pantallas?
          </Accordion.Title>
        </div>


        {activeIndex === 4 &&
          <Accordion.Content>
            <p style={{paddingBottom: "40px"}}>
              Sí. ScreenFox se puede conectar a cualquier pantalla con entrada HDMI.
            </p>
          </Accordion.Content>
        }

      </Accordion>
    )
  }
}
